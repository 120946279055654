body, html {
    margin: 0;
    padding: 0;
    font-family: 'Courier New', Courier, monospace;
    background-color: black;
    color: #00FF41;
    height: 100%;
    overflow: hidden;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    position: relative;
    z-index: 2;
}

.welcome-text, h2 {
    font-size: 2em;
    color: #00FF41;
    opacity: 0;
    animation: fadeIn 3s forwards, moveUp 4s forwards ease-in-out;
    margin-bottom: 20px;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes moveUp {
    0% { transform: translateY(100px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    animation: showForm 5s forwards;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    z-index: 2;
    width: 300px;
}

@keyframes showForm {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

input, select, button {
    background-color: black;
    border: 1px solid #00FF41;
    padding: 10px;
    margin: 5px 0;
    color: #00FF41;
    font-size: 1em;
    width: 100%;
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box;
}

select {
    padding: 10px;
}

button {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

button:hover {
    background-color: #00FF41;
    color: black;
}

.error-message {
    color: red;
    font-size: 0.9em;
    margin-top: -5px;
    margin-bottom: 5px;
    text-align: left;
    width: 100%;
}

.user-page {
    padding: 20px;
    background-color: black;
    color: #00FF41;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.header h1 {
    margin: 0;
    font-size: 1.8em;
}

.search-input {
    padding: 10px;
    width: 300px; 
    border: 1px solid #00FF41;
    background-color: black;
    color: #00FF41;
    border-radius: 4px;
    font-size: 1em;
}

table {
    width: 80%;
    margin: 20px auto;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    border: 1px solid #00FF41;
    text-align: center;
}

th {
    background-color: #333;
}

.error-message {
    color: red;
    text-align: center;
}

.admin-page {
    padding: 20px;
    background-color: black;
    color: #00FF41;
    text-align: center;
    height: 100vh; 
    overflow-y: auto; 
}

.category-section {
    margin-bottom: 20px; 
    padding: 8px; 
    border: 1px solid #00FF41;
    border-radius: 8px;
    max-width: 600px; 
    margin-left: auto;
    margin-right: auto;
}

h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #00FF41;
    text-transform: uppercase;
    font-weight: bold;
    animation: none; 
    opacity: 1 !important; 
}

.form-group {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

label {
    font-size: 1em;
    margin-bottom: 5px;
}

select {
    padding: 6px; 
    width: 250px;
    background-color: black;
    color: #00FF41;
    border: 1px solid #00FF41;
    border-radius: 4px;
    font-size: 0.9em;
    margin-bottom: 10px;
}

.hint {
    font-size: 0.85em; 
    color: #999999;
    text-align: center;
    margin-top: 5px;
}

button {
    padding: 10px 20px;
    background-color: black;
    color: #00FF41;
    border: 1px solid #00FF41;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    margin-top: auto;
    width: fit-content;
    align-self: center;
    margin-bottom: 60px; 
}

button:hover {
    background-color: #00FF41;
    color: black;
}

